<template>
  <div class="backoffice-view list-view activity">
		<div class="page-header">
			<div class="title">
				<h1>Actividad</h1>
			</div>
			<div class="btn-container">
				<button
					class="btn btn-primary btn-lg"
					@click="syncData"
				>
					Sincronizar datos
				</button>
			</div>
		</div>
		<div class="loading" v-if="useBackofficeStore().loading"></div>
		<div
			class="table-container"
			v-else-if="activity"
		>
			<div class="table-tools">
				<div class="filters">
					<div class="filter">
						<select v-model="filters.filterEventType">
							<option value="undefined" selected>Tipo de actividad</option>
							<option
								v-for="event in eventTypes"
								:key="event"
								:value="event"
							>{{ event }}</option>
						</select>
					</div>
					<div class="filter">
						<select v-model="filters.filterEntityId">
							<option value="undefined" selected>ID de entidad</option>
							<option
								v-for="id in entityIds"
								:key="id"
								:value="id"
							>{{ id }}</option>
						</select>
					</div>
					<div class="filter">
						<input type="date" v-model="filters.filterDate">
					</div>
				</div>
			</div>
			<table v-if="filteredData.length > 0">
				<thead>
					<th>DNI</th>
					<th>Tipo de actividad</th>
					<th>Entidad</th>
					<th>ID de la entidad</th>
					<th>Fecha</th>
					<th>Hora</th>
				</thead>
				<tbody>
					<tr
						v-for="event in filteredData"
						:key="event.id"
					>
						<td>
							<router-link
								:to="{
									name: 'UserActivityList',
									query: {
										user: event.user
									}
								}"
							>
								{{ dniById(event.user) }}
							</router-link>
						</td>
						<td>{{ event.activity }}</td>
						<td>{{ event.entity }}</td>
						<td>{{ event.entityId }}</td>
						<td>{{ getFormattedDate(event.creationDate) }}</td>
						<td>{{ getFormattedTime(event.creationDate) }}</td>
				</tr>
				</tbody>
			</table>
			<div v-else>
				<p>No hay resultados.</p>
				<!-- <div
					v-if="activity.length == 0"
				>
					<p>No hay actividad.</p>
				</div>
				<div
					v-else-if="activity.length > 0 && filteredData.length == 0"
				>
					
				</div> -->
			</div>
		</div>
  </div>
</template>

<script setup>
import { useBackofficeStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'AppActivityList',
	data() {
		return {
			filters: {
				filterEventType: undefined,
				filterDate: undefined,
				filterEntityId: undefined
			}
		}
	},

	computed: {
		activity() {
			return useBackofficeStore().activity
		},

		filteredData() {
			var filteredActivity = this.activity
			if (this.filters.filterEventType) {
				filteredActivity = filteredActivity.filter((act) => act.activity == this.filters.filterEventType)
			}

			if (this.filters.filterDate) {
				filteredActivity = filteredActivity.filter((act) => act.creationDate?.split('T')[0] == this.filters.filterDate)
			}

			if (this.filters.filterEntityId) {
				filteredActivity = filteredActivity.filter((act) => act.entityId == this.filters.filterEntityId)
			}

			return filteredActivity
    },

		users() {
			return useBackofficeStore().users
		},

		eventTypes() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.activity))]
		},

		entityIds() {
			return [...new Set(useBackofficeStore().activity?.map(act => act.entityId))]
		}
	},

	methods: {
		dniById(id) {
			return this.users.find(user => user.userId == id)?.dni
		},

		getFormattedDate(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[0]
			}
		},

		getFormattedTime(datetime) {
			if (datetime) {
				var split = datetime.split('T')
				return split[1].split('.')[0]
			}
		},

		syncData() {
			fetch('https://api.rondatech.com.ar/api/data/backup/manual/dbsync', {
			method: 'GET',
			headers: new Headers({
				'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'),
			}),
		})
		.then(response => response.json())
		.then(data => console.log(data))
		.catch(error => console.error('Error:', error))
		}
	},

	created() {
	},

	mounted() {
	},


}
</script>
<style scoped>
	select {
		appearance: auto;
	}

	.filters {
		display: flex;
		gap: 8px;
		align-items: stretch;
	}

	.filter {
		display: flex;
	}
</style>
