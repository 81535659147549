<template>
  <div class="backoffice-view list-view users">
		<div class="page-header">
			<div class="title">
				<h1>Pacientes</h1>
			</div>
			<router-link
					:to="{name:'AppUserCreate'}"
				>
				<button class="btn btn-primary btn-lg">
					Agregar paciente
				</button>
			</router-link>
		</div>
		<div
			class="table-container"
		>
			<div class="table-tools flex-v">
				<input type="search" placeholder="Buscar paciente por CUID, DNI, nombre o apellido" v-model="searchQuery">
				<div class="filters flex-h-start gap24">
					<div class="filter flex-h-start gap4">
						<input type="checkbox" :checked="filterByNoEmail ? true : undefined" v-model="filterByNoEmail" id="filterNoEmailCheck">
						<label for="filterNoEmailCheck">No validaron mail</label>
					</div>
					<div class="filter flex-h-start gap4">
						<input type="checkbox" :checked="filterByNoActivity ? true : undefined" v-model="filterByNoActivity" id="filterNoActivityCheck">
						<label for="filterNoActivityCheck">No tienen actividad</label>
					</div>
				</div>
			</div>
			<div
				v-if="users.length == 0 || filteredData.length == 0"
			>
				<p v-if="users.length == 0">No hay pacientes cargados.</p>
				<p v-if="users.length > 0 && filteredData.length == 0">No hay resultados.</p>
			</div>
			<table v-else>
				<thead>
					<th>CUID</th>
					<th>Nombre y apellido</th>
					<th>DNI</th>
					<th>Teléfono</th>
					<th>Fecha de alta en el sistema</th>
					<th></th>
				</thead>
				<tbody>
					<tr
						v-for="user in filteredData"
						:key="user.cuid"
					>
						<td>{{ user.cuid }}</td>
						<td>
							<span>{{ user.name + ' ' + user.lastName }}</span>
							<!-- <span class="badge bg-orange" v-if="!user.hasValidEmail">No validó mail</span> -->
						</td>
						<td>{{ user.dni || '-' }}</td>
						<td>{{ user.phone || '-' }}</td>
						<td>{{ formattedDate(user.creationDate) || '-'}}</td>
						<td>
							<div class="btn-container">
								<router-link
									:to="{
										name: 'AppUserCreate',
										query: {cuid: user.cuid}
									}"
								>
									<button class="btn btn-secondary btn-sm">datos</button>
								</router-link>
								<router-link
									:to="{
										name: 'UserActivityList',
										query: {user: user.userId}
									}"
								>
									<button class="btn btn-secondary btn-sm">actividad</button>
								</router-link>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
  </div>
</template>

<script setup>
import { useBackofficeStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'AppUsersList',
	data() {
		return {
			searchQuery: '',
			filterByNoEmail: false,
			filterByNoActivity: false
		}
	},

	computed: {
		filteredData() {
			let filteredUsers = this.users
			if (this.filterByNoEmail) {
				filteredUsers = filteredUsers.filter((user) => !user.hasValidEmail)
			}

			if (this.filterByNoActivity) {
				filteredUsers = filteredUsers.filter((user) => !this.activity?.find(act => act.user == user.userId))
			}

			if (this.searchQuery.length > 0) {
				filteredUsers = filteredUsers.filter((user) => {
					return (
						user.name?.toLowerCase().includes(this.searchQuery.toLowerCase())
						|| user.lastName?.toLowerCase().includes(this.searchQuery.toLowerCase())
						|| `${user.name?.toLowerCase()} ${user.lastName?.toLowerCase()}`.includes(this.searchQuery.toLowerCase())
						|| user.cuid == this.searchQuery
						|| user.dni == this.searchQuery
					);
				});
			}

			return filteredUsers
    },
	
		users() {
			return useBackofficeStore().users
		},

		activity() {
			return useBackofficeStore().activity
		}
	},

	methods: {
		formattedDate(date) {
			var split = date.split(' ')[0].split('-')
			return `${split[2]}/${split[1]}/${split[0]}`
		}
	},

	mounted() {
	}
}
</script>
<style scoped>
.table-tools {
	display: flex;
	gap: 8px;
}
</style>
